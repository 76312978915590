import styles from"./styles.module.css"
import AidKit from "../../assets/decoration/aidkit_deco.svg"

function PageRequest() {
    return (
        <div className={styles.pageRequestMain}>
            <p className={styles.pageRequestTitle}>Запросы, <span>с которыми я работаю</span></p>
            <div className={styles.pageRequestContent}>
                <ul className={styles.pageRequestList}>
                    <li>Консультирование семей по здоровому питанию, образу жизни, безопасному быту;</li>
                    <li>Информирование родителей об анализах и диагностике;</li>
                    <li>Аллергия у детей и пищевые непереносимости;</li>
                    <li>Атопический дерматит и подбор экологичных уходовых средств;</li>
                    <li>Поллиноз, экзема, астма, псориаз;</li>
                    <li>Аденоиды;</li>
                    <li>Гормональная коррекция;</li>
                    <li>Паразитарные, грибковые инвазии;</li>
                    <li>Аутоиммунные заболевания;</li>
                    <li>Частые отиты, ларингиты и т.д.;</li>
                    <li>Коррекция митохондриальной дисфункции;</li>
                    <li>Восстановление микрофлоры кишечника;</li>
                    <li>Воспаление дефицитов витаминов;</li>
                    <li>Коррекция задержки речи у детей, СДВГ, РАС;</li>
                    <li>Коррекция пищевого поведения, в том числе и у детей с РАС</li>
                    <li>Ввод прикорма, обучение жеванию, кусочкам;</li>
                    <li>Консультирование родителей по профилактике заболеваний, частых ОРВИ, осложнений после антибиотиков;</li>
                    <li>Консультирование по закаливанию;</li>
                </ul>
            </div>
            <div className={styles.decoration}>
                <img src={AidKit} />
            </div>
        </div>
    )
}

export default PageRequest;