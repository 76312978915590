import s from "./styles.module.css";
function PageContact() {
    return (
        <div className={s.container}>
            <p className={s.title}>контакты</p>
            <div className={s.wrapper}>

                <p className={s.subTitle}>
                    Жду твоего звонка!
                </p>
                <a href="tel:89872685443" className="btn btn-success">
                    +7 987 268-54-43
                </a>
                <p className={s.subTitle} style={{ marginTop: "64px" }}>или пиши на почту:</p>

                <a href="mailto:rezedavm@mail.ru?subject=Записаться на консультацию&body=Хочу записаться на консультацию.">rezedavm@mail.ru</a>

            </div>
        </div>
    )
}

export default PageContact;