import PhotoViewer from "../../components/photo_viewer/photo_viewer";
import useModal from "../../components/use_modal/use_modal";
import s from "./styles.module.css";

import photoOne from "../../assets/photos/f1.jpg";
import photoTwo from "../../assets/photos/f2.jpg";
import photoThree from "../../assets/photos/f3.jpg";
import arrow from "../../assets/icons/nav_arrow.svg";

function PageInfo() {

    const [isShow, toggleModal] = useModal();

    return (
        <div className={s.pageInfoMain}>
            <PhotoViewer isShow={isShow} onHide={toggleModal} />
            <p className={s.pageInfoUpTitle}>меня зовут</p>
            <div className={s.pageInfoTitleButton}>
                <p className={s.pageInfoTitle}>Резеда</p>
                <button onClick={toggleModal}>посмотреть диплом</button>
            </div>
            <div className={s.pageInfoContent}>
                <div className={s.pageInfoContentTile}>
                    <img src={photoOne} />
                    <p>мама троих детей</p>
                </div>
                <div className={s.pageInfoContentTile}>
                    <img />
                    <p>дипломированный</p>
                    <p>нутрициолог</p>
                </div>
                <div className={s.pageInfoContentTile}>
                    <img src={photoThree}/>
                    <p>помогаю людям</p>
                    <p>наладить питание</p>
                </div>
            </div>
            <div className={s.pageInfoFooter}>
                <p>проблемы со здоровьем</p>
                <img src={arrow} />
                <p>моя консультация</p>
                <img src={arrow} />
                <p>море энергии и лучшее качество жизни</p>
            </div>
        </div>
    );
}

export default PageInfo;

