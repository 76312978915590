import React from 'react';
import logo from './logo.svg';
import './App.css';

import "./fonts/seed_jp_eb.ttf";
import "./fonts/seed_kr_bd.ttf";
import "./fonts/seed_kr_rg.ttf";
import PageTitle from './pages/page_title/page';
import PageActivity from './pages/page_activity/page';
import PageInfo from './pages/page_info/page';
import PageRequest from './pages/page_requests/page';
import PageResults from './pages/page_results/page';
import PageFeedback from './pages/page_feedback/page';
import PageContact from './pages/page_contact/page';


function App() {
  return (
    <>
      <PageTitle />
      <PageActivity/>
      <PageInfo/>
      <PageRequest/>
      <PageResults/>
      <PageFeedback/>
      <PageContact/>
    </>
  );
}

export default App;
