import arrow from "../../assets/decoration/arrow.svg"
import "./styles.css"

function BoxArrow() {
    return (
        <div className="boxArrowContainer">
            <div className="boxArrowTitle">
                <p>Знаю всё</p>
                <p>о влиянии пищи</p>
                <p>на организм</p>
            </div>
            <img src={arrow} className="boxArrowImage" />
        </div>
    );
}

export default BoxArrow;