import s from './styles.module.css';
import title from '../../assets/photos/title.png';
import BoxArrow from '../../components/box_arrow/box_arrow';
function PageTitle() {
    return (
        <div className={s.pageTitleMain}>
            <div className={s.pageTitleContainer}>
                <p className={s.pageTitleFirst}>НУТРИ</p>
                <p className={s.pageTitleSecond}>ЦИОЛОГ</p>
                <p className={s.pageTitleSubTitle}>с <span className={s.pageTitleSubTitleColored}>интегративным</span> подходом</p>
            </div>
            <div className={s.pageTitleImageContainer}>
                <BoxArrow />
                <img className={s.pageTitleImage} src={title} />
            </div>
        </div>
    );
}

export default PageTitle;