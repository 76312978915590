import Activity, { ActivityEnums } from "../../components/activity/activity";
import "./styles.css"

function PageActivity() {
    return (
        <div className="pageActivityMain">
            <div className="pageActivityFirstLine">
                <Activity title="составлю" subTitle="здоровый рацион" image={ActivityEnums.Food} />
                <Activity title="подберу лечебный протокол" subTitle="по вашим запросам" image={ActivityEnums.Documents} />
                <Activity title="расскажу, как избавиться" subTitle="от заболеваний" image={ActivityEnums.AidKit} />
            </div>
            <div className="pageActivitySecondLine">
                <Activity title="помогу восстановить" subTitle="микрофлору кишечника" image={ActivityEnums.Stomach} />
                <Activity title="объясню, как восполнить" subTitle="дефицит витаминов" image={ActivityEnums.Vitamin} />
                <Activity title="проанализирую причины" subTitle="детских болезней" image={ActivityEnums.Child} />
            </div>
        </div>

    );
}

export default PageActivity;