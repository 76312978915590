import VitaminDecoration from "../../assets/decoration/vitamin_deco.svg";
import ChildDecoration from "../../assets/decoration/child_deco.svg";
import check from "../../assets/icons/check.svg";

import "./styles.css";

function PageResults() {
    return (
        <div className="pageResultMain">
            <p className="pageResultTitle">результаты</p>
            <p className="pageResultSubTitle">моих клиентов</p>
            <div className="pageResultContent">
                <div className="pageResultContentItem">
                    <p className="title">У взрослых</p>
                    <ul className="pageRequestList">
                        <li><img src={check}/> снизился вес</li>
                        <li><img src={check}/> улучшилось качество волос и ногтей</li>
                        <li><img src={check}/> стабилизировалась работа нервной системы</li>
                    </ul>
                </div>
                <div className="pageResultContentItem">
                    <p className="title">У детей</p>
                    <ul className="pageRequestList">
                        <li><img src={check}/> прошла аллергия</li>
                        <li><img src={check}/> активировалась речь</li>
                        <li><img src={check}/> нормализовались сон и стул</li>
                    </ul>
                </div>
            </div>
            <div className="decoration">
                <img className="decoVitamin" src={VitaminDecoration} />
                <img className="decoChild" src={ChildDecoration} />
            </div>
        </div>
    );
}

export default PageResults;