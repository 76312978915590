import { FC } from "react";
import diplom from "../../assets/photos/diplom.png"
import "./styles.css"

interface PhotoViewerProps {
    isShow: boolean;
    onHide: () => void;

}

const PhotoViewer: FC<PhotoViewerProps> = ({ isShow, onHide }) => {
    if (!isShow) {
        return null;
    }

    return (
        <div className="photoViewerWrapper" onClick={onHide}>
            <img src={diplom}/>
        </div>
    );
}

export default PhotoViewer;