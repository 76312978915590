import styles from "./styles.module.css";
import "./styles.css";
import { FC, ReactNode, useEffect, useState } from "react";
import React from "react";
import Slider from "react-slick";

const data = [
    {
        name: "Алина",
        feedback: "Я к тебе обратилась с проблемой аденоидов,это просто не проблема,а проблемище мое,сын чуть что сразу воспалились аденоиды и всё в кашель ужасный,постоянно приходилось дышать пульмикорт,беридуалом. По твоей рекомендации я пересмотрела рацион своего ребенка,ввела коррективы минимальные,потому что все сладкое,сахар,шоколады,чипсы,соки и всякую гадость у нас дома никто не ест и не покупает!) Добавки, которые ты прислала,в нос закапывали и промывали нос,как ты учила по специальной технологии 🔥😃и вуаля,результат как на глаза,сын простыл,но обошлось всё без абструкции и ингаляций….даже наш пульмонолог удивился🔥спасибо тебе огромное❤очень рада,что обратилась к тебе и твои консультации нам очень помогли🙏🏼🙏🏼🙏🏼"
    },
    {
        name: "Марина",
        feedback: "Привет! Хочу выразить тебе огромное спасибо за помощь и поддержку Я обратилась к тебе с просьбой помочь в решении проблемы. У трехмесячной дочки начали появляться прожилки крови в стуле. Врачи ничего толком не могли сказать. Взяла у тебя консультацию и ведение на месяц. Ты все мне подробно объяснила. Что все лечится и проходит быстро. Написала что и как нужно мне делать. Посоветовала лечебный протокол. Сейчас нам под года, у нас абсолютно все прошло. Анализы все чистые без нареканий. Ребенок здоров  и весел) начали прикорм по твоим советам не с банок а с микро доз и тд Нет высыпаний, нет газов и колик все отлично. Помимо этого на протоколе я привела в порядок свой жкт. После родов вес уходит гораздо быстрее на таком питании. Чувствую себя отлично Огромное тебе спасибо! За столько месяцев общения ни разу не оставила без ответа, всегда на связи! Это очень ценно!!!"
    },
    {
        name: "Наталья",
        feedback: "На ведении 2 недели Меньше вздутия, была сильно сухая кожа, сейчас получше. Эмоционально стала более спокойной. ☺ Хотя до сих пор бывает сложно с собой договориться. Как говорится, знать одно, а делать это другое. Но стоит того, изменения колоссальные."
    },
    {
        name: "Эльза",
        feedback: "Сыну 5 лет ему, прям вааау эффект, он перестал истерить. Мог валяться и кричать очень громко, каждый день!!! Кажжый день утренние сборы в сад были с истериками. Из сада по дороге домой тоже кричал, особенно если отказывала покупать сладкое. Сейчас спокойно реагирует. В выходные истерил по полдня.🤪 Он весьма избирателен и раньше отказывался от мяса, сейчас соглашается на большее. 🙏🏼 Это чудо какое-то))) Дальше больше!"
    },
    {
        name: "Татьяна",
        feedback: "Дочке 8 мес на гв, высыпания меньше, кожа стала значительно суше. Стул каждый день, это долгожданная победа!"
    },
    {
        name: "Ольга",
        feedback: "У сына были аденоиды, храпел во сне, часто дышал ртом днём. После обращения к вам очень редко болеет, нос дышит прекрасно, во сне беззвучно и спокойно дышит."
    },
    {
        name: "Елена",
        feedback: `Обратилась с проблемой лишнего веса, а по итогу решила множество вопросов помимо веса, восстановила гормональный фон, прошло состояние "встала и уже устала", появилась энергия и радость. И всё это без сотен баночек с добавками!`
    },
    {
        name: "Гузель",
        feedback: "Благодаря вам я сэкономила уйма денег, ведь вместо того, что бы пойти покупать уже верхнюю одежду ещё бОльшего размера, пришла на консультацию к вам и вот я счастлива носить свою одежду, и покупать новую но меньших размеров. Похудела, оздоровилась, стала счастливой!"
    },
    {
        name: "Кристина",
        feedback: "Обратилась с проблемой выпадением волос, с слоящимися ногтями, аллергическими высыпаниями... Спустя 3 месяца уже прошла аллергия, остаётся лишь увлажнять кожу, волосы стали меньше выпадать, ногти теперь не ломаются, стала увереннее в себе, появилось желание жить."
    }
]

interface UserInfoProps {
    userName: string;
}

interface Props {
    userName: string;
    feedback: string;
}

const UserInfo: FC<UserInfoProps> = ({ userName }) => {

    const getWord = () => {
        return userName[0];
    }

    return (
        <div className={styles.userInfo}>
            <div>
                {getWord()}
            </div>
            <p style={{ flex: 1 }}>{userName}</p>
        </div>
    )
}

const UserFeedback: FC<Props> = ({ userName, feedback }) => {
    return (
        <div className={styles.container}>
            <UserInfo userName={userName} />
            <p>{feedback}</p>
        </div>
    )
}

interface feedbackProps {
    children: ReactNode;
}


function PageFeedback() {

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        arrows: false,
        dotsClass: "dots",
        focusOnSelect: true,
        rows: 1, // Вертикальное скольжение отключаем
        slidesPerRow: 2,
        slidesToShow: 1, //  На больших экранах
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    });

    const handleResize = () => {
        const width = window.innerWidth;
        if (width < 1090) {
            setSettings({ ...settings, slidesToShow: 1, slidesToScroll: 1, slidesPerRow: 1 });
        } else if (width < 1600) {
            setSettings({ ...settings, slidesToShow: 2, slidesToScroll: 2, slidesPerRow: 2 });
        } else if (width < 2000) {
            setSettings({ ...settings, slidesToShow: 3, slidesToScroll: 3, slidesPerRow: 2 });
        } else if (width < 2500) {
            setSettings({ ...settings, slidesToShow: 4, slidesToScroll: 4, slidesPerRow: 2 });
        } else {
            setSettings({ ...settings, slidesToShow: 5, slidesToScroll: 5, slidesPerRow: 2 });
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={styles.pageFeedbackMain}>
            <p className={styles.title} style={{ color: "white", textAlign: "center", marginTop: "56px" }}>ОТЗЫВЫ</p>
            <div className={styles.sliderContainer}>
                <Slider {...settings}>
                    {data.map((feedback, index) => <UserFeedback userName={feedback.name} feedback={feedback.feedback} key={index} />)}
                </Slider>

            </div>
        </div>
    )
}

export default PageFeedback;
