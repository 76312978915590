import { FC } from 'react'
import './styles.css'

import AidKit from '../../assets/icons/aidkit.svg'
import Child from '../../assets/icons/child.svg'
import Documents from '../../assets/icons/documents.svg'
import Stomach from '../../assets/icons/stomach.svg'
import Vitamin from '../../assets/icons/vitamin.svg'
import Food from '../../assets/icons/food.svg'

export enum ActivityEnums {
    Food = "Food",
    AidKit = "AidKit",
    Child = "Child",
    Documents = "Documents",
    Stomach = "Stomach",
    Vitamin = "Vitamin",
}

interface ActivityProps {
    title: string,
    subTitle: string,
    image: ActivityEnums
}

const Activity: FC<ActivityProps> = ({ title, subTitle, image }) => {

    var img = AidKit;

    switch (image) {
        case ActivityEnums.AidKit:
            img = AidKit;
            break;
        case ActivityEnums.Child:
            img = Child;
            break;
        case ActivityEnums.Documents:
            img = Documents;
            break;
        case ActivityEnums.Stomach:
            img = Stomach;
            break;
        case ActivityEnums.Vitamin:
            img = Vitamin;
            break;
        default:
            img = AidKit;
    }


    return (
        <div className='activityContainer'>
            <img src={img}></img>
            <p>{title}</p>
            <p>{subTitle}</p>
        </div>
    );
}

export default Activity;